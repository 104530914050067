import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When connecting things outside Kubernetes to Kubernetes services,
we recommend using Smallstep Certificate Manager in combination with `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/step-issuer"
      }}><inlineCode parentName="a">{`step-issuer`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https://cert-manager.io/docs/"
      }}>{`Kubernetes’ cert-manager`}</a>{` utility. `}</p>
    <h2>{`Before you begin`}</h2>
    <p>{`You will need:`}</p>
    <ul>
      <li parentName="ul">{`An account on the Smallstep platform. Need one? `}<a parentName="li" {...{
          "href": "https://smallstep.com/signup?product=cm"
        }}>{`Register here`}</a>{`.`}</li>
      <li parentName="ul">{`An Authority in Certificate Manager that will act as your upstream CA.`}</li>
      <li parentName="ul">{`Additionally, we assume that you have a functioning Kubernetes Cluster already running and accessible.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/graphics/k8s-step-issuer-diagram.png",
        "alt": "kubernetes diagram"
      }}></img></p>
    <h3>{`Dependencies`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://smallstep.com/docs/step-ca/installation"
        }}><inlineCode parentName="a">{`step`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kubernetes.io/docs/tasks/tools/#kubectl"
        }}><inlineCode parentName="a">{`kubectl`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://helm.sh/docs/intro/install/"
        }}><inlineCode parentName="a">{`helm`}</inlineCode></a></li>
    </ul>
    <h2>{`Add a Certificate Manager Provisioner`}</h2>
    <p>{`First, we'll add a Certificate Manager provisioner for `}<inlineCode parentName="p">{`step-issuer`}</inlineCode>{` to use.
In this case, we will create a JWK provisioner.
Running `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-cli/reference/beta/ca/provisioner/add"
      }}>{`the following command`}</a>{` will start an authentication flow to Certificate Manager.
Upon success, it will request a password for the new provisioner.
Make sure that this password is saved in your password manager.
You'll need it for later steps.`}</p>
    <CodeBlock language="shell-session" copyText='step ca provisioner add "step-issuer" --type JWK --create' mdxType="CodeBlock">
      {`$ step ca provisioner add "step-issuer" --type JWK --create
`}
    </CodeBlock>
    <p>{`Upon success, you will see the provisioner’s configuration returned as a successful response. `}</p>
    <p>{`Run the `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-cli/reference/ca/provisioner/list"
      }}>{`following command`}</a>{`, and make a note of the new provisioner’s `}<inlineCode parentName="p">{`“kid”`}</inlineCode>{` field (the `}<em parentName="p">{`5th line listed`}</em>{`; it will be required in later steps):`}</p>
    <CodeBlock language="shell-session" copyText="step ca provisioner list | grep step-issuer -A 9" mdxType="CodeBlock">
      {`$ step ca provisioner list | grep step-issuer -A 9
"name": "step-issuer",
      "key": {
         "use": "sig",
         "kty": "EC",
         "kid": "c39XHcunqE...BbR0xhl7I",
         "crv": "P-256",
         "alg": "ES256",
         "x": "abQRrRWF6cMlhRvpQlAZNLWUmwYjWi0MJvspgw",
         "y": "jeIdFtUu5lZwZacDeX8nElNtZPpQrW70WyUKOo"
},
`}
    </CodeBlock>
    <h2>{`Install and Configure `}<inlineCode parentName="h2">{`cert-manager`}</inlineCode></h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/cert-manager/cert-manager"
      }}><inlineCode parentName="a">{`cert-manager`}</inlineCode></a>{` is a Kubernetes certificate management controller.
This important add-on helps issue and renew certificates.
Switch to the correct `}<inlineCode parentName="p">{`kubectl`}</inlineCode>{` context for your Kubernetes Cluster,
and run the following commands to install `}<inlineCode parentName="p">{`cert-manager`}</inlineCode>{` to the cluster:`}</p>
    <CodeBlock language="shell-session" copyText="helm repo add jetstack https://charts.jetstack.io" mdxType="CodeBlock">
      {`$ helm repo add jetstack https://charts.jetstack.io
"jetstack" has been added to your repositories
`}
    </CodeBlock>
    <CodeBlock language="shell-session" copyText="helm repo update" mdxType="CodeBlock">
      {`$ helm repo update
Hang tight while we grab the latest from your chart repositories...
...Successfully got an update from the "jetstack" chart repository
Update Complete. ⎈Happy Helming!⎈
`}
    </CodeBlock>
    <p>{`Next, run:`}</p>
    <CodeBlock language="shell-session" copyText="helm install
     cert-manager jetstack/cert-manager 
     --namespace cert-manager 
     --create-namespace 
     --version v1.7.1 
     --set installCRDs=true" mdxType="CodeBlock">
      {`$ helm install \\
     cert-manager jetstack/cert-manager \\
     --namespace cert-manager \\
     --create-namespace \\
     --version v1.7.1 \\
     --set installCRDs=true
NAME: cert-manager
LAST DEPLOYED: Thu Mar 17 14:19:59 2022
NAMESPACE: cert-manager
STATUS: deployed
REVISION: 1
TEST SUITE: None
NOTES:
cert-manager v1.7.1 has been deployed successfully!
  
In order to begin issuing certificates, you will need to set up a ClusterIssuer
or Issuer resource (for example, by creating a 'letsencrypt-staging' issuer).
  
More information on the different types of issuers and how to configure them
can be found in our documentation:
  
https://cert-manager.io/docs/configuration/
  
For information on how to configure cert-manager to automatically provision
Certificates for Ingress resources, take a look at the 'ingress-shim'
documentation:
  
https://cert-manager.io/docs/usage/ingress/
`}
    </CodeBlock>
    <p>{`Now that `}<inlineCode parentName="p">{`cert-manager`}</inlineCode>{` is installed, confirm your pods are running:`}</p>
    <CodeBlock language="shell-session" copyText="kubectl get pods -n cert-manager" mdxType="CodeBlock">
      {`$ kubectl get pods -n cert-manager
NAME                                       READY   STATUS    RESTARTS   AGE
cert-manager-6d6bb4f487-gdtrj              1/1     Running   0          3m17s
cert-manager-cainjector-7d55bf8f78-hqhnm   1/1     Running   0          3m17s
cert-manager-webhook-577f77586f-n5q9h      1/1     Running   0          3m17s
`}
    </CodeBlock>
    <h2>{`Install & Connect `}<inlineCode parentName="h2">{`step-issuer`}</inlineCode></h2>
    <p><inlineCode parentName="p">{`step-issuer`}</inlineCode>{` is a `}<inlineCode parentName="p">{`cert-manager`}</inlineCode>{` `}<inlineCode parentName="p">{`CertificateRequest`}</inlineCode>{` controller that signs the certificate requests from the cluster. We’ll need to connect it to your Certificate Manager authority before it can start signing certificates.`}</p>
    <p>{`To install `}<inlineCode parentName="p">{`step-issuer`}</inlineCode>{` into your cluster, first add the Smallstep repository:`}</p>
    <CodeBlock language="shell-session" copyText="helm repo add smallstep  https://smallstep.github.io/helm-charts" mdxType="CodeBlock">
      {`$ helm repo add smallstep  https://smallstep.github.io/helm-charts
"smallstep" has been added to your repositories
`}
    </CodeBlock>
    <p>{`Next, run:`}</p>
    <CodeBlock language="shell-session" copyText="helm repo update" mdxType="CodeBlock">
      {`$ helm repo update
Hang tight while we grab the latest from your chart repositories...
...Successfully got an update from the "jetstack" chart repository
...Successfully got an update from the "smallstep" chart repository
Update Complete. ⎈Happy Helming!⎈
`}
    </CodeBlock>
    <p>{`Next, run:`}</p>
    <CodeBlock language="shell-session" copyText="helm install 
     step-issuer smallstep/step-issuer 
     --namespace step-issuer 
     --create-namespace" mdxType="CodeBlock">
      {`$ helm install \\
     step-issuer smallstep/step-issuer \\
     --namespace step-issuer \\
     --create-namespace
NAME: step-issuer
LAST DEPLOYED: Thu Mar 17 15:39:29 2022
NAMESPACE: default
STATUS: deployed
REVISION: 1
TEST SUITE: None
NOTES:
⚙️  To get step-issuer up and running follow the next steps:
....
`}
    </CodeBlock>
    <p>{`Now that `}<inlineCode parentName="p">{`step-issuer`}</inlineCode>{` is installed, let's confirm its pods are running:`}</p>
    <CodeBlock language="shell-session" copyText="kubectl get pods -n step-issuer" mdxType="CodeBlock">
      {`$ kubectl get pods -n step-issuer
NAME                           READY   STATUS    RESTARTS   AGE
step-issuer-56cc6c4498-g4pl2   2/2     Running   0          63s
`}
    </CodeBlock>
    <p>{`We start initializing `}<inlineCode parentName="p">{`step-issuer`}</inlineCode>{` using the base64 representation of the Certificate Manager authority’s Root Certificate. Do so by running the following `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-cli/reference/base64"
      }}>{`command`}</a>{`:`}</p>
    <CodeBlock language="shell-session" copyText="step ca root | step base64" mdxType="CodeBlock">
      {`$ step ca root | step base64
LS0tLS1CRUdJTWhrak9....pzdnZ3bHpHcFg4eFJFRVJUSUZJQ0FUtCg==
`}
    </CodeBlock>
    <p>{`In addition to this value, also gather your authority URL, provisioner password, and provisioner `}<inlineCode parentName="p">{`kid`}</inlineCode>{`. Use these values to populate `}<inlineCode parentName="p">{`issuer-config.yaml`}</inlineCode>{` below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: certmanager.step.sm/v1beta1
kind: StepIssuer
metadata:
 name: step-issuer
 namespace: step-issuer
spec:
 # The CA URL.
 url: https://\${authority name}.\${team name}.ca.smallstep.com
 # The base64 encoded version of the CA root certificate in PEM format.
 caBundle: \${base64 Value Above}
 # The provisioner name, kid, and a reference to the provisioner password secret.
 provisioner:
   name: step-issuer
   kid: \${Provisioner \`kid\`}
   passwordRef:
     name: step-issuer-provisioner-password
     key: password
`}</code></pre>
    <p>{`Create a Kubernetes `}<a parentName="p" {...{
        "href": "https://kubernetes.io/docs/concepts/configuration/secret/"
      }}>{`Secret`}</a>{` holding the provisioner password for the `}<inlineCode parentName="p">{`step-issuer`}</inlineCode>{` provisioner you’ve set up in Certificate Manager:`}</p>
    <CodeBlock language="shell-session" copyText="kubectl create secret \\
		-n step-issuer generic step-issuer-provisioner-password \\
		--from-literal=password=<your provisioner password>" mdxType="CodeBlock">
      {`$ kubectl create secret \\
		-n step-issuer generic step-issuer-provisioner-password \\
		--from-literal=password=<your provisioner password>
secret/step-issuer-provisioner-password created
`}
    </CodeBlock>
    <p>{`Apply `}<inlineCode parentName="p">{`issuer-config.yaml`}</inlineCode>{` to the cluster:`}</p>
    <CodeBlock language="shell-session" copyText="kubectl apply -f issuer-config.yaml" mdxType="CodeBlock">
      {`$ kubectl apply -f issuer-config.yaml
stepissuer.certmanager.step.sm/step-issuer created
`}
    </CodeBlock>
    <p>{`Moments later, you should be able to see the status property in the resource:`}</p>
    <CodeBlock language="shell-session" copyText="kubectl get stepissuers.certmanager.step.sm step-issuer
    -n step-issuer 
    -o yaml" mdxType="CodeBlock">
      {`$ kubectl get stepissuers.certmanager.step.sm step-issuer \\
    -n step-issuer \\
    -o yaml
apiVersion: certmanager.step.sm/v1beta1
kind: StepIssuer
...
status:
  conditions:
  - lastTransitionTime: "2022-03-17T16:09:51Z"
    message: StepIssuer verified and ready to sign certificates
    reason: Verified
    status: "True"
    type: Ready
`}
    </CodeBlock>
    <p>{`At this point, `}<inlineCode parentName="p">{`step-issuer`}</inlineCode>{` is ready to begin signing certificates.`}</p>
    <p><inlineCode parentName="p">{`step-issuer`}</inlineCode>{` has a controller watching for CertificateRequest resources.
To create this request we first need to create a CSR with `}<inlineCode parentName="p">{`step`}</inlineCode>{`.
This `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-cli/reference/certificate/create"
      }}>{`command`}</a>{` will ask for a password to encrypt the private key;
generate a password in your password manager for this step:`}</p>
    <CodeBlock language="shell-session" copyText="step certificate create --csr 
    internal.smallstep.com internal.csr internal.key" mdxType="CodeBlock">
      {`$ step certificate create --csr \\
    internal.smallstep.com internal.csr internal.key
Your certificate signing request has been saved in internal.csr.
Your private key has been saved in internal.key.
`}
    </CodeBlock>
    <p>{`Encode the new CSR using base64:`}</p>
    <CodeBlock language="shell-session" copyText="cat internal.csr | step base64" mdxType="CodeBlock">
      {`$ cat internal.csr | step base64
LS0tLxRWFJIdH....LS0tLS0=
`}
    </CodeBlock>
    <p>{`Now, create `}<inlineCode parentName="p">{`certificate-request.yaml`}</inlineCode>{` and replace the request value with the base64 value of your new CSR:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: cert-manager.io/v1
kind: CertificateRequest
metadata:
 name: internal-smallstep-com
 namespace: step-issuer
spec:
 # The base64 encoded version of the certificate request in PEM format.
 request: \${base64 Value from Above}
 # The duration of the certificate
 duration: 24h
 # If the certificate will be a CA or not.
 isCA: false
 # A reference to the issuer in charge of signing the CSR.
 issuerRef:
   group: certmanager.step.sm
   kind: StepIssuer
   name: step-issuer
`}</code></pre>
    <p>{`And now apply `}<inlineCode parentName="p">{`certificate-request.yaml`}</inlineCode>{` to the cluster:`}</p>
    <CodeBlock language="shell-session" copyText="kubectl apply -f certificate-request.yaml" mdxType="CodeBlock">
      {`$ kubectl apply -f certificate-request.yaml
certificaterequest.cert-manager.io/internal-smallstep-com created
`}
    </CodeBlock>
    <p>{`And moments later the bundled signed certificate with the intermediate as well as the root certificate will be available in the resource:`}</p>
    <CodeBlock language="shell-session" copyText="kubectl get certificaterequests.cert-manager.io internal-smallstep-com 
    -n step-issuer 
    -o yaml" mdxType="CodeBlock">
      {`$ kubectl get certificaterequests.cert-manager.io internal-smallstep-com \
    -n step-issuer \
    -o yaml
apiVersion: cert-manager.io/v1
kind: CertificateRequest
metadata:
....
conditions:
  - lastTransitionTime: "2022-03-18T09:59:26Z"
    message: Certificate request has been approved by cert-manager.io
    reason: cert-manager.io
    status: "True"
    type: Approved
  - lastTransitionTime: "2022-03-18T09:59:27Z"
    message: Certificate issued
    reason: Issued
    status: "True"
    type: Ready
`}
    </CodeBlock>
    <p>{`Now, you are ready to use the TLS certificate in your app.`}</p>
    <h2>{`Using the Certificate Resource`}</h2>
    <p>{`Before supporting CertificateRequest, `}<inlineCode parentName="p">{`cert-manager`}</inlineCode>{` supported the resource Certificate; this allows you to create TLS certificates providing only X.509 properties like the common name, DNS, or IP address SANs. `}<inlineCode parentName="p">{`cert-manager`}</inlineCode>{` now provides a method to support Certificate resources using CertificateRequest controllers like `}<inlineCode parentName="p">{`step-issuer`}</inlineCode>{`.`}</p>
    <p>{`The YAML for a Certificate resource looks like example `}<inlineCode parentName="p">{`certificate.yaml`}</inlineCode>{` below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: cert-manager.io/v1
kind: Certificate
metadata:
  name: backend-smallstep-com
  namespace: step-issuer
spec:
  # The secret name to store the signed certificate
  secretName: backend-smallstep-com-tls
  # Common Name
  commonName: backend.smallstep.com
  # DNS SAN
  dnsNames:
    - localhost
    - backend.smallstep.com
  # IP Address SAN
  ipAddresses:
    - "127.0.0.1"
  # Duration of the certificate
  duration: 24h
  # Renew 8 hours before the certificate expiration
  renewBefore: 8h
  # The reference to the step issuer
  issuerRef:
    group: certmanager.step.sm
    kind: StepIssuer
    name: step-issuer
`}</code></pre>
    <p>{`To apply the certificate resource, run:`}</p>
    <CodeBlock language="shell-session" copyText="kubectl apply -f certificate.yaml" mdxType="CodeBlock">
      {`$ kubectl apply -f certificate.yaml
certificate.cert-manager.io/backend-smallstep-com created
`}
    </CodeBlock>
    <p>{`Moments later, a `}<inlineCode parentName="p">{`CertificateRequest`}</inlineCode>{` will be automatically created by cert-manager:`}</p>
    <CodeBlock language="shell-session" copyText="kubectl get certificates.cert-manager.io -n step-issuer" mdxType="CodeBlock">
      {`$ kubectl get certificates.cert-manager.io \\
        -n step-issuer
NAME                    READY   SECRET                      AGE
backend-smallstep-com   True    backend-smallstep-com-tls   18s
`}
    </CodeBlock>
    <p><inlineCode parentName="p">{`step-issuer`}</inlineCode>{` gets this `}<inlineCode parentName="p">{`CertificateRequest`}</inlineCode>{` and sends the signing request to Certificate Manager and stores the signed certificate in the same resource. `}<inlineCode parentName="p">{`cert-manager`}</inlineCode>{` retrieves the signed certificate and stores the signed certificate/key pair in the `}<inlineCode parentName="p">{`Secret`}</inlineCode>{` denoted in the YAML file property `}<inlineCode parentName="p">{`secretName`}</inlineCode>{`.`}</p>
    <CodeBlock language="shell-session" copyText="kubectl get secrets backend-smallstep-com-tls 
       -n step-issuer 
		-o yaml" mdxType="CodeBlock">
      {`$ kubectl get secrets backend-smallstep-com-tls \\
       -n step-issuer \\
	   -o yaml
apiVersion: v1
data:
  ca.crt: LS0tLS1CRtLS....0FURS0tLS0tCg==
  tls.crt: LS0tKVjJXTj....dNSd3Qc3a0tLQo=
  tls.key: LS0tLS1CRUd....JTiBSU0LS0tLQo=
kind: Secret
metadata:
  annotations:
    cert-manager.io/alt-names: localhost,backend.smallstep.com
    cert-manager.io/certificate-name: backend-smallstep-com
    cert-manager.io/common-name: backend.smallstep.com
    cert-manager.io/ip-sans: 127.0.0.1
    cert-manager.io/issuer-group: certmanager.step.sm
    cert-manager.io/issuer-kind: StepIssuer
    cert-manager.io/issuer-name: step-issuer
    cert-manager.io/uri-sans: ""
  creationTimestamp: "2022-03-22T12:37:57Z"
  name: backend-smallstep-com-tls
  namespace: step-issuer
  resourceVersion: "305291"
  uid: fc54d1e7-4501-409b-b71b-e2c560504709
type: kubernetes.io/tls
`}
    </CodeBlock>
    <p>{`That's it. You can now reference this secret from your app to access the certificate.
No headaches involved, no custom tools required, and one less problem to think about.
Zero Trust doesn’t have to be hard to implement:
With the right tools, it’s simple to “set it and forget it” the first time around and automatically have mutual TLS at hand.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      